const { getBrowser, requestPermission, getPermissionLevel } = require('./permissions')
const { getCookie, setCookie, deleteCookie } = require('./cookies')
const { loadScript } = require('./scripts')
const { debounce } = require('./timing')
const { docIsReady, isInViewport, tabVisibilityChange } = require('./window')

// eslint-disable-next-line import/newline-after-import
;(() => {
  window.NEXUtils = {
    requestPermission,
    getPermissionLevel,
    getBrowser,
    getCookie,
    setCookie,
    deleteCookie,
    loadScript,
    debounce,
    isInViewport,
    tabVisibilityChange,
    docIsReady
  }
})()
